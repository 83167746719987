<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <br>
    <h2 class="title-style">
      <v-icon class="icon-style">
        mdi-account-check-outline
      </v-icon> Work Order Check-Ins
    </h2>
    <br>
    <v-btn
      small
      color="red darken-3"
      @click="$router.push({ name: 'workOrders' })"
    >
      <v-icon left>
        mdi-chevron-double-left
      </v-icon>
      Back
    </v-btn>
    <v-row v-if="isWorkOrderCheckInsAvailable">
      <v-col
        cols="12"
        md="6"
      >
        <v-card
          class="user-card"
          color="ECEFF1"
          elevation="3"
        >
          <p class="user-content">
            Job Number:- <font
              class="font-my-style"
            >
              {{ workOrderCheckIns.job_details.job_number }}
            </font>
          </p>
          <p class="user-content">
            Job Description:- <font
              class="font-my-style"
            >
              {{ workOrderCheckIns.job_details.description }}
            </font>
          </p>
          <p class="user-content">
            High Risk Work:- <font
              :color="getHrwColor(workOrderCheckIns.job_details.high_risk)"
            >
              {{ workOrderCheckIns.job_details.high_risk | displayHighRisk }}
            </font>
          </p>
        </v-card>
      </v-col>
      <v-col
        v-if="isOrgInvoiceEnabled"
        cols="12"
        md="6"
      >
        <v-card
          class="user-card"
          color="ECEFF1"
          elevation="3"
        >
          <p class="user-content">
            Quote Amount:- <font
              class="font-my-style"
            >
              {{ workOrderCheckIns.job_details.quote_amount }} /-
            </font>
          </p>
          <p class="user-content">
            Invoiced Amount:- <font
              class="font-my-style"
            >
              {{ workOrderCheckIns.total_invoice }} /-
            </font>
          </p>
          <p class="user-content">
            Amount Difference: <font
              class="difference-font"
              :color="getDifferenceColor()"
            >
              {{ amountDifference }} /-
            </font>
          </p>
        </v-card>
      </v-col>
    </v-row>
    <v-card
      shaped
      color="#CFD8DC"
    >
      <br>
      <br>
      <br>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              dense
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="checkIns.headers"
        :items="checkInRecords"
        :search="search"
        class="elevation-1"
        mobile-breakpoint="100"
        disable-sort
        light
      >
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.service_provider.first_name }} {{ item.service_provider.last_name }}
            </td>
            <td>{{ item.service_provider.company.company_name }}</td>
            <td>
              <font v-if="item.service_provider.contractor_category !== null">
                {{ item.service_provider.contractor_category.name }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>{{ item.site.name }}</td>
            <td>{{ item.checkin_time | convertToLocal }}</td>
            <td>
              <font
                v-if="item.checkout_time === null"
                class="My-checkout-time"
              >
                Active*
              </font><font v-else>
                {{ item.checkout_time | convertToLocal }}
              </font>
            </td>
            <td v-if="isOrgInvoiceEnabled">
              <font v-if="item.invoice_amount !== null">
                {{ item.invoice_amount }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>
              <font
                v-if="item.status !== null"
                class="job-status-style"
                :color="getStatusColor(item.status)"
              >
                {{ item.status }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
            <td>
              <font v-if="item.pending_reason !== null">
                {{ item.pending_reason }}
              </font>
              <font v-else>
                N/A
              </font>
            </td>
          </tr>
        </template>
      </v-data-table>
      <div class="py-3" />
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-container>
</template>
<script>
  import spinner from 'src/views/dashboard/component/SpinnerCentre';
  import moment from 'moment';
  import ModuleHelper from 'src/helpers/module-helper';
  import Constants from 'src/constants';

  export default {
    name: 'WorkOrderCheckIns',
    components: {
      'centre-spinner': spinner,
    },
    filters: {
      convertToLocal (stringDatetime) {
        return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
      },
      displayHighRisk (status) {
        if (status === true) {
          return 'Yes*';
        } else {
          return 'No';
        }
      },
    },
    data () {
      return {
        search: '',
        loading: false,
        checkIns: {
          headers: [
            { text: 'Name', align: 'start', value: 'name' },
            { text: 'Company', value: 'company_name' },
            { text: 'Category', value: 'category_name' },
            { text: 'Site', value: 'name' },
            { text: 'Check-In Time', value: 'checkin_time' },
            { text: 'Check-out Time', value: 'checkout_time' },
            { text: 'Status', value: 'status' },
            { text: 'Pending Reason', value: 'pending_reason' },
          ],
        },
        helper: new ModuleHelper(),
      };
    },
    computed: {
      workOrderCheckIns () {
        return this.$store.getters['workorders/getWorkOrderCheckIns'];
      },
      isWorkOrderCheckInsAvailable () {
        if (Object.keys(this.workOrderCheckIns).length > 0) {
          return true;
        }
        return false;
      },
      amountDifference () {
        if (Object.keys(this.workOrderCheckIns).length > 0) {
          const amount = this.workOrderCheckIns.job_details.quote_amount - this.workOrderCheckIns.total_invoice;
          return amount;
        }
        return 0;
      },
      checkInRecords () {
        const checkInDetails = this.workOrderCheckIns.checkin_records;
        if (this.isWorkOrderCheckInsAvailable === true) {
          const sortedList = checkInDetails.sort((a, b) => {
          return new Date(b.created_at) - new Date(a.created_at);
        });
        return sortedList;
        }
        return [];
      },
      isOrgInvoiceEnabled () {
        return (this.helper.isSubModuleExist(Constants.MODULE_WORK_ORDERS, Constants.SUB_MODULE_INVOICE));
      },
    },
    watch: {
      isOrgInvoiceEnabled (val) {
        this.showInvoiceAmount();
      },
    },
    created () {
      this.showInvoiceAmount();
    },
    async mounted () {
      await this.fetchWorkOrderCheckIns();
    },
    methods: {
      async fetchWorkOrderCheckIns () {
        const workOrderId = this.$route.params.workOrderId;
        this.loading = true;
        await this.$store.dispatch('workorders/fetchWorkOrderCheckIns', {
          workOrderId: workOrderId,
        });
        this.loading = false;
      },
      showInvoiceAmount () {
        if (this.isOrgInvoiceEnabled === true && this.checkIns.headers.some(item => item.text !== 'Invoice')) {
          this.checkIns.headers.splice(6, 0, { text: 'Invoice', value: 'invoice' });
        }
      },
      getStatusColor (status) {
        if (status === 'completed') {
          return '#2E7D32';
        } else {
          return '#C62828';
        }
      },
      getHrwColor (value) {
        if (value === true) return '#C62828';
        else { return '#2E7D32'; }
      },
      getDifferenceColor () {
        if (this.workOrderCheckIns.job_details.quote_amount >= this.workOrderCheckIns.total_invoice) {
            return '#2E7D32';
        }
        return '#C62828';
      },
    },
  };
</script>
<style scoped>
.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}
.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}
.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC
}
.v-data-table { background-color: #ECEFF1; }
.title-style {
  color: #37474F;
}
.icon-style {
  color: #37474F;
  font-size: 45px;
}
.type-style {
  text-transform: capitalize;
  font-weight: bold;
}
.my-style {
  margin-bottom: 10px !important;
}
.my-hrw-style {
  text-transform: capitalize;
  font-weight: bold;
}
.My-checkout-time {
  color:#2E7D32;
  font-weight: bold;
}
.status-font-style {
  font-family: 'Times New Roman', Times, serif;
  text-transform: capitalize;
  font-weight: bold;
}
.job-status-style {
  text-transform: capitalize;
  font-weight: bold;
}
.user-content {
  font-size: 14px;
  font-weight: bold;
  color: #546E7A;
  margin-left: 15px;
  line-height: 0.8;
  font-family:'Times New Roman', Times, serif;
}
.font-my-style {
  font-weight: bold;
  font-size: 14px;
  color: #37474F;
  font-family:'Times New Roman', Times, serif;
}
.difference-font {
  font-weight: bold;
  font-size: 14px;
  font-family:'Times New Roman', Times, serif;
}
.user-card {
  border-bottom: 5px solid #546E7A !important;
  padding-top: 15px;
}
</style>
